<template>
  <span
    class="tooltip"
    @focus="expandTooltip()"
    @blur="expanded = false"
    tabindex="0"
    role="button"
  >
    <icon icon="x-fa-question-circle" size="m"> </icon>
    <transition name="fade-popup">
      <span v-if="expanded" class="popup">
        {{ cleanTooltip(tooltip) }}
      </span>
    </transition>
  </span>
</template>

<script>
import Icon from "vue-elements/icon/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: ["tooltip", "analyticsData"],
  data: function () {
    return {
      expanded: false,
    };
  },
  methods: {
    cleanTooltip(tooltip) {
      return tooltip.replace("\n", "<br>");
    },
    expandTooltip() {
      this.expanded = true;
      if (this.analyticsData !== null) {
        this.logAnalyticsEvent('minicomparer.tooltip', {...this.analyticsData, tooltip: this.tooltip})
      }
    },
  },
};
</script>

<style lang="scss">
// TODO Make better scss
@use 'assets/scss/_helpers/icon' as icon;
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;
@use 'assets/scss/_helpers/typography'as typography;
@use 'assets/scss/_variables/typographies'as typographies;

.tooltip {
  @include icon.color(colors.$text-subtle);

  // Padding with negative margin to expand click area
  padding: 8px;

  @include breakpoint.up(xl) {
    position: relative;
  }

  &:focus {
    outline: none;
  }

  > .icon-element {
    width: 16px;
    height: 16px;
  }

  .popup {
    @include typography.font(typographies.$fonts, body-alt);

    display: flex;
    color: colors.$block-base;
    background-color: colors.$text-subtle;
    z-index: 5;
    padding: 12px 24px;
    white-space: pre-line;
    position: absolute;
    margin: 16px auto;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @include breakpoint.up(xl) {
      width: 240px;
      bottom: -50%;
      left: 100%;

      padding: 24px;
    }
  }

  .fade-popup-enter-from,
  .fade-popup-leave-to {
    opacity: 0;
    @include breakpoint.up(xl) {
      transform: translateX(16px);
    }
  }

  .fade-popup-enter-active {
    transition: all 0.2s ease-out;
    overflow: hidden;
  }

  .fade-popup-leave-active {
    transition: all 0.2s ease-out;
    overflow: hidden;
  }
}
</style>
