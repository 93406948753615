<template>
  <span class="usp" :class="[{ 'usp-present': icon === 'x-fa-gift', 'usp-regular': icon !== 'x-fa-gift' }, extraClass]"
    @click="$emit('click')">
    <icon :icon="icon" size="m"></icon>
    <slot></slot>
    <span class="cashback-icon" v-if="showInfoIcon">
      <icon icon="x-fa-info-circle" size="m" v-if="extraClass === 'cashback'"></icon>
    </span>
    <Tooltip v-if="tooltip" :text="tooltip" position="top-right" icon="x-fa-question-circle"></Tooltip>

  </span>
</template>

<style lang="scss">
@use 'assets/scss/_helpers/breakpoint' as breakpoint;
@use 'assets/scss/_variables/colors' as colors;

.usps .usp {
  cursor: pointer;

  .tooltip {
    @include breakpoint.up("xl") {
      position: relative;
    }

    .popup {
      margin: 8px 0;
      width: 100%;
      left: 0;
      bottom: 100%;

      @include breakpoint.up("xl") {
        width: 240px;
        top: unset;
        bottom: 0;
        left: 100%;
      }
    }

    .icon-element {
      margin-left: 4px;
      background-color: colors.$text-subtle;
    }
  }

  .cashback-icon {
    margin-left: 6px;
  }
}
</style>

<script>
import Tooltip from "../../../abstracts/elements/tooltip/tooltip.vue";
import Icon from "../icon/Icon.vue";

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: ["icon", "tooltip", "analyticsCategory", "analyticsLabel", "extraClass", "showInfoIcon"],
};
</script>
